import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchInterviews(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/interviews", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchCandidates() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/candidates")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        getCustomFields() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/feedbacks/create")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchAssignedInterviews(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/my-interviews", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchInterview(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/interviews/${id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateInterview(ctx, interviewData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `/interviews/${interviewData.get('id')}`,
                        interviewData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        }
                    )
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeInterview(ctx, interviewId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/interviews/${interviewId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        addFeedback(ctx, interviewData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `interview/${interviewData.id}/add_review`,
                        interviewData.data
                    )
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        fetchFeedback(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/fetch/feedback/${id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        removeMultipleInterview(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/deleteMultipleInterviews/", { items: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }
};
